import React, { useEffect, useState } from 'react';
//import { DataList, Modal as OtcModal, Divider, IconArrowRightFilled, IconCalendarDateOutlined, Button as OtcButton } from 'otc-ui-component-library';
import { Button, Heading, Modal, Notification } from '@auspost/postmaster-react';
import styles from './MaintainBatch.module.scss';
import { emptyProduct } from '../components/organisms/ProductDetail';
import ProductSelect from '../components/molecules/ProductSelect';
import { AxiosError } from 'axios';
import portalClient from '../utils/PortalClient';
import { Spinner } from '../components/Shared';


const MaintainBatch = ({ partner, isActivateView }:{ partner: any, isActivateView?: any }) => {
    const [batches, setBatches] = useState<any>([]);
    const [searching, setSearching] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [detail, setDetail] = useState(emptyDetail);
    const [withdraw, setWithdraw] = useState(emptyDetail);

    useEffect(() => {
        setBatches([]);
    }, [product?.ProductID])

    const doSearch = async () => {
        console.log('Searching...', product);
        setBatches([]);
        setSearching(true);
        try {
            let url = `/api/portal/batches?ilngProductID=${product?.ProductID}`;
            url += isActivateView ? "&loaded=Y" : "";
            let response = await portalClient.get(url);
            console.log('Got Batches:', response.data);
            setBatches(response.data);
            setSearching(false);        
        }
        catch(error) {
            setSearching(false);
        }
    }

    const loadBatchDetail =  async (batch: any) => {
        if(batch.Loaded)
            return batch;
            try {
                let i = batches.indexOf(batch);
                let detailResp = await portalClient.get(`/api/portal/batches/${batch.BatchID}`);
                if(detailResp.status === 200) {
                    let detail = {...batch, ...detailResp.data[0], Loaded: true};                
                    console.log("batch detail:", detail);                    
                    let newBatches = [...batches];
                    newBatches[i] = detail;
                    setBatches(newBatches);
                    return detail;
                }
            }
            catch(error) {
                console.log("Error loading batch:", error);
                return {...batch, Error: error, Loaded: true};
            }
    }

    const doOpenDetail = async (batch: any) => {        
        setDetail(batch);
        let detail = await loadBatchDetail(batch);
        setDetail(detail);
    }

    const doOpenWithdraw = async (batch: any) => {        
        setWithdraw(batch);
        let detail = await loadBatchDetail(batch);
        setWithdraw(detail);
    }

    const doActivateBatch = async (batch: any) => {
        let detail = batch;
        let i = batches.indexOf(batch);
        try {            
            let resp = await portalClient.post(`/api/portal/batches/${batch.BatchID}`);
            if(resp.status === 200) {
                detail = {...detail, Status: "Activated"};                            
            }
            else
                throw new Error("Unexpected response status");
        }
        catch(error: any) {
            console.log("Error activating:", error);
            detail = {...batch, 
                        Error: (error.response?.data?.length > 0 && error.response?.data[0].ErrorMessage) ?? error,
                        Loaded: true, Status: "Error"};
            //console.log("Error detail:", detail);
        }
        let newBatches = [...batches];
        newBatches[i] = detail;
        setBatches(newBatches);
        return detail;
    }

    const doWithdrawBatch = async (batch: any) => {        
        let detail = batch;
        let i = batches.indexOf(batch);
        try {            
            let resp = await portalClient.delete(`/api/portal/batches/${batch.BatchID}`);
            if(resp.status === 200) {
                detail = {...detail, Status: "Withdrawn"};                            
            }
            else
                throw new Error("Unexpected response status");
        }
        catch(error:any) {
            console.log("Error withdrawing:", error);
            detail = {...batch, 
                        Error: (error.response?.data?.length > 0 && error.response?.data[0].ErrorMessage) ?? error,
                        Loaded: true, Status: "Error"};
            //console.log("Error detail:", detail);
        }
        setWithdraw(emptyDetail);
        let newBatches = [...batches];
        newBatches[i] = detail;
        setBatches(newBatches);
        return detail;
    }

    return (
        <>
            <div className={`main container ${styles.maintainBatch}`}>
                {/*<Heading level="h1">Maintain Batch</Heading>*/}
                <div className="table-container">
                    <div className="header-container">
                        <div className="header">
                            <Heading level="h3">
                                {isActivateView ? "Activate" : "Maintain"} Batch
                            </Heading>
                            <div className="actions">
                                {product && <Button size="small" variant="primary" onClick={doSearch}>Search</Button>}
                            </div>
                        </div>
                    </div>
                    <div className="table-content">
                        <div className='flex-container fields row'>
                            <div className='flex-row'>
                                <div className="flex-cell-fill">
                                    <ProductSelect product={product} setProduct={setProduct} partner={partner} />
                                </div>
                                <div className="flex-cell-5"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                {searching ? <Spinner /> :
                batches?.length > 0 &&
                    <>
                        <div className="table-container batches">
                            <div className="header-container">
                                <div className="header">
                                    <Heading level="h3">
                                        Batches Found
                                    </Heading>
                                </div>
                            </div>                            
                            <table className='table-content' style={{ width: "100%" }}>
                                <tbody>
                                {batches?.length === 1 && batches[0].StatusCode === 1 ? 
                                    <tr><td></td><td>
                                        <Notification isDismissible={false} className='flex-cell-fill' status="info" type="alert"
                                            message={batches[0].ErrorMessage} /><br />
                                    </td><td></td></tr> : 
                                <>
                                    <tr className="row header"><th>Time Uploaded</th><th>Batch No.</th><th>Uploaded By</th><th>Qty Available</th><th></th></tr>
                                    {batches.map((b: any) =>
                                        <tr key={b.BatchID} className="row"><td>{b.DateUploaded}</td><td>{b.BatchCode}</td><td>{b.UploadedBy}</td><td>{b.Quantity}</td>
                                            <td>
                                                <div className='flex-container'>
                                                    { b.Status === "Error" ? <>{b.Error ?? b.Status}</>
                                                        : b.Status ? <>{b.Status}</>
                                                        : isActivateView ? <>
                                                            <Button size="medium" variant='tertiary' className='flex-cell-6' onClick={() => doActivateBatch(b)}>Activate</Button>
                                                        </>
                                                        : <>
                                                            {b.Quantity !== "Not Active" && <Button size="medium" variant='tertiary' className='flex-cell-6' onClick={() => doOpenWithdraw(b)}>Withdraw</Button>}
                                                            <Button size="medium" variant='tertiary' className='flex-cell-6' onClick={() => doOpenDetail(b)}>Details</Button>
                                                        </>}
                                                </div>
                                            </td>
                                        </tr>
                                    )}                                
                                    {/*<tr className="row footer"><td><Button size="small" variant="secondary">Show more results</Button></td><td>Showing {tableData.length} of xxx</td><td></td></tr>*/}
                                </>}
                                </tbody>
                            </table>
                        </div>
                    </>}
                {detail &&                    
                    <Modal ariaDialogLabel='Batch Details' isModalShowing={detail.BatchID > 0} onHide={() => { setDetail(emptyDetail); }}>
                        {detail.Loaded ? 
                        <>
                            <Modal.Header>
                                {detail.Error ? <>Error Loading Batch</> : <>Batch Detail: {detail.ProductName}</>}
                            </Modal.Header>
                            <Modal.Content>
                                {detail.Error ? <>{detail.Error?.message}</> : 
                                <>
                                    {detail.ProductName}<br />
                                    {detail.DenomimationName}<br />
                                    <hr />
                                    <table className="detail-info">
                                        <tbody>
                                            <tr><td>{detail.Quantity}</td><td>at</td><td>{detail.AmountInDollars}</td><td>=&nbsp;{detail.ExtendedAmount}</td></tr>
                                            <tr><td>Total:</td><td></td><td></td><td>=&nbsp;{detail.ExtendedAmount}</td></tr>
                                        </tbody>
                                    </table>
                                    <hr />
                                    <label>Batch Number: </label> {detail.BatchCode}<br />
                                    <label>Date Uploaded: </label> {detail.DateUploaded}<br /><br />
                                    Please report any descrepancies to your Australia Post Business/Product manager
                                </>}
                            </Modal.Content>
                            {!detail.Error && <Modal.Footer alignment="right">
                                <Button onClick={() => { setWithdraw(detail); setDetail(emptyDetail); }}
                                    size="medium" variant="primary">
                                    Withdraw
                                </Button>
                            </Modal.Footer>}
                        </> 
                        : <><Modal.Content>Loading Detail... <Spinner /></Modal.Content></>}
                    </Modal>}
                    {withdraw && <Modal ariaDialogLabel='Widthdraw Batch' isModalShowing={withdraw.BatchID >= 0} onHide={() => { setWithdraw(emptyDetail); }}>
                        {withdraw.Loaded ? 
                        <>
                            <Modal.Header>
                                Batch Detail: {withdraw.ProductName}
                            </Modal.Header>
                            <Modal.Content>
                                Are you sure you want to withdraw batch:<br /><br />
                                {withdraw.BatchCode} ({withdraw.ProductName} - {withdraw.DenomimationName})
                            </Modal.Content>
                            <Modal.Footer alignment="right">
                                <Button onClick={() => { setWithdraw(emptyDetail); }} size="medium" variant="secondary">
                                    Cancel
                                </Button>
                                <Button onClick={() => { doWithdrawBatch(withdraw); }} size="medium" variant="primary">
                                    Yes
                                </Button>
                            </Modal.Footer>
                        </> : <></>}
                    </Modal>}
            </div>
        </>
    );
}

const emptyDetail = {BatchID: -1, ProductName: "", DenomimationName: "", Quantity: "", AmountInDollars: 0.0, ExtendedAmount: 0.0, BatchCode: "", DateUploaded: new Date(), 
                    Loaded: false, Error: (null as unknown as AxiosError)};

export default MaintainBatch;
